import { NotificationManager } from "react-notifications";
import { api } from "api";
import { handleActions } from "redux-actions";

const PARAMETRO = 'PARAMETRO';

export const listarVendedores = () => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const params = { id };
    return api
        .get("auto_ventas/vendedores", params)
        .then((response) => {
            return response;
        })
        .catch(() => {
            return [];
        });
};

export const datosDelVendedor = (vendedorId) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const action = "ver";
    const params = { id, action };
    return api
        .get(`auto_ventas/vendedores/${vendedorId}`, params)
        .then((response) => {
            return response;
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrio un error al obtener los datos",
                "Error",
                2000,
                null,
                true
            );
            return [];
        });
};

export const darDeBajaVendedor = (data) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    const action = "dar_de_baja";
    data.activo = false;
    const params = { id, action, ...data };
    return api
        .put(`auto_ventas/vendedores/${data.vendedor}`, params)
        .then(() => {
            NotificationManager.success(
                `Se le ha dado de baja al vendedor`,
                "Exito",
                2000,
                null,
                true
            );
            return [];
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrio un error al dar de baja",
                "Error",
                2000,
                null,
                true
            );
            return [];
        });
};

export const crearVendedor = (data) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split("/");
    let id = id_emp[5];
    data.empresa = id;
    const params = { data };
    return api
        .post("auto_ventas/vendedores", params)
        .then(() => {
            NotificationManager.success(
                "Vendedor agregado",
                "Exito",
                3000,
                null,
                true
            );
            return true;
        })
        .catch((error) => {
            NotificationManager.error(error.msj, "Error", 2000, null, true);
            return false;
        });
};

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    })
}

export const actions = {
    crearVendedor,
    listarVendedores,
    darDeBajaVendedor,
    parametrosEmpresa
};

export const reducers = {
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    datos: [],
};

export default handleActions(reducers, initialState);
