import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/cuenta_contable';
import ParametroCuentaContable from './ParametroCuentaContableForm';

const ms2p = (state) => {
  return {
    ...state.vm_cuenta_contable,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ParametroCuentaContable);
