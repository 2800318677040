import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import moment from 'moment';

class OtrosGastosFacturas extends Component{
    
    render(){
        const { loader,  detalle } = this.props;
        return(
            <React.Fragment>
                {detalle &&
                    <Grid 
                        hover 
                        striped 
                        data={detalle} 
                        loading={loader}
                        headerStyle={ { background: 'black' } } 
                        //onSortChange={onSortChange} 
                    >
                    <TableHeaderColumn
                        isKey
                        dataField="fecha_registro"
                        width="115"
                        dataFormat={(cell, row)=>{
                            return moment(cell).format("DD/MM/YYYY");
                        }}
                        dataSort
                        thStyle={ 
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Fecha
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="serie"
                        dataSort
                        width="150"
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Serie
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="numero"
                        width="150"
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Numero
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="identificador"
                        dataSort
                        width="150"
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Nit
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        width="300"
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Proveedor
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign='right'
                        headerAlign="center"
                        dataField="cantidad"
                        width="100"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Cantidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        headerAlign="center"
                        dataAlign='right'
                        width="150"
                        dataFormat={(cell, row)=>{
                            return new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(cell);    
                        }}
                        dataSort
                        thStyle={
                            {color: 'white'}}
                        tdStyle={
                            {color: 'white'}}
                    >
                        Total
                    </TableHeaderColumn>
                </Grid>
                }
            </React.Fragment>
        );
    }
}

export default OtrosGastosFacturas;
