import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderDayPickerB,
    AsyncSelectField,
    renderFieldRadio,
    SelectField,
    renderNumber
} from "../Utils/renderField/renderField";

function InformesForm(props) {
    const {
        modulo,
        loader,
        listarProductos,
        listarFases,
        listarClientes,
        listarSeriesProd,
        listarBodegas,
        reporteKardexValorizado,
        reporteProduccionDetallePro,
        reporteIngresosProdDetalle,
        reporteProduccionTrazPro,
        excelReporteProduccionDetalle,
        reporteConsumos,
        listarPilotos,
        reporteControlCajasPiloto,
        listarInsumos,
        reporteExistenciaCajas,
        excelReporteConsumos,
        listarSeriesNotaCobro,
        reporteListadoNotasDeCobro,
        reporteExistenciaCajasExcel,
        reporteKardexCajas,
        reporteCosechas,
        reporteCXCDetalleEnvio,
        loaderC,
        monedas,
        listarPeriodos,
        listaPeriodo,
        reporteCostoLibra,
        excelReporteControlCajasPiloto,
        reporteVentaFase,
        excelListadoNotasDeCobro,
    } = props;

    const [estadoModalKardex, cambiarEstadoModalKardex] = useState(false);
    const [estadoModalProdD, cambiarEstadoModalProdD] = useState(false);
    const [estadoModalTrazPro, cambiarEstadoModalTrazPro] = useState(false);
    const [estadoModalConsumo, cambiarEstadoModalConsumo] = useState(false);
    const [estadoModalIngresosProdDet, cambiarEstadoModalIngresosProdDet] = useState(false);
    const [opcion, setOpcion] = useState('P');
    const [sel, setSel] = useState('P');
    const [estadoModalControlCJ, cambiarEstadoModalControlCJ] = useState(false);
    const [estadoModalKardexCajas, cambiarEstadoModalKardexCajas] = useState(false);
    const [estadoModalNotasDeCobro, cambiarEstadoModalNotasDeCobro] = useState(false);
    const [estadoModalExistenciaCajas, cambiarEstadoModalExistenciaCajas] = useState(false);
    const [estadoModalCXCDetalleEnvio, cambiarEstadoModalCXCDetalleEnvio] = useState(false);
    const [estadoModalCostoLibra, cambiarEstadoModalCostoLibra] = useState(false);
    const [estadoModalVentaFase, cambiarEstadoModalVentaFase] = useState(false);

    const onOptionChange = e => {
        setOpcion(e.target.value)
        setSel(e.target.value)
      }

    let autoFocus = true;
    let titulo = 'Informes';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');

    const handleOnChange = (e, value) => {
        if (value.length == 4) {
            listarPeriodos(value)
        }

    }

    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalKardex(!estadoModalKardex)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Kardex valorizado
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalNotasDeCobro(!estadoModalNotasDeCobro)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de notas de cobro</a>
                        </div>
                    </div>
                </div>
                <h4 className='mt-5' style={{ color: "white" }}>Producción</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalProdD(!estadoModalProdD)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Producción detalle</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalTrazPro(!estadoModalProdD)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Trazabilidad producción</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalConsumo(!estadoModalConsumo)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Consumo de producción</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalIngresosProdDet(!estadoModalIngresosProdDet)}
                                data-bs-toggle="list"
                                role="tab"
                                aria-controls="list-home">Ingresos de produccion detalle
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <LoadMask loading={loaderC} dark>
                                <button class="list-group-item list-group-item-action"
                                    id="list-home-list"
                                    onClick={() => reporteCosechas()}
                                    data-bs-toggle="list"
                                    role="tab"
                                    aria-controls="list-home">Cosechas
                                </button>
                            </LoadMask>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalVentaFase(!estadoModalVentaFase)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Ventas por producto</a>
                        </div>
                    </div>
                </div>
                <h4 className='mt-5' style={{ color: "white" }}>Específicos</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalControlCJ(!estadoModalControlCJ)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Control de cajas por piloto</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalKardexCajas(!estadoModalKardexCajas)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Kardex de cajas</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalExistenciaCajas(!estadoModalExistenciaCajas)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Existencia de cajas</a>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalCXCDetalleEnvio(!estadoModalCXCDetalleEnvio)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">CXC detalle por envío</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <a class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalCostoLibra(!estadoModalCostoLibra)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/produccion/${id_emp[5]}/informes`}
                                aria-controls="list-home">Calculo costo por libra</a>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModalKardex} //Kardex
                    cambiarEstado={cambiarEstadoModalKardex}
                    titulo="Kardex valorizado"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    loadOptions={listarBodegas}
                                    component={AsyncSelectField}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteKardexValorizado}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalKardex(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalProdD} //Produccion detalle
                    cambiarEstado={cambiarEstadoModalProdD}
                    titulo="Producción detalle"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="fase"
                                    placeholder="Llave..."
                                    className="form-control-find"
                                    loadOptions={listarFases}
                                    component={AsyncSelectField}
                                    msj="Llave"
                                />
                            </div>
                            <div className="mb-3 col-sm-12 col-md-4">
                                <fieldset>
                                    <Field
                                        
                                        type="radio"
                                        name="producto"
                                        label="Producto"
                                        value="P"
                                        checked={opcion === "P"}
                                        component={renderFieldRadio}
                                        onChange={onOptionChange}
                                    />
                                    <Field
                                        
                                        type="radio"
                                        name="llave"
                                        label="Llave"
                                        value="L"
                                        checked={opcion === "L"}
                                        component={renderFieldRadio}
                                        onChange={onOptionChange}
                                    />
                                </fieldset>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => reporteProduccionDetallePro(sel)}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelReporteProduccionDetalle}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalProdD(false)}
                                >
                                    Cancelar
                                </button>
                                
                            </Contenido>
                        </LoadMask>
                </Modal> 
                <Modal
                    estado={estadoModalTrazPro} //Trazabilidad Produccion
                    cambiarEstado={cambiarEstadoModalTrazPro}
                    titulo="Trazabilidad producción"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => reporteProduccionTrazPro()}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalTrazPro(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalConsumo} //Produccion consumo
                    cambiarEstado={cambiarEstadoModalConsumo}
                    titulo="Consumo de producción detalle"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="bodega"
                                    placeholder="Bodega..."
                                    className="form-control-find"
                                    loadOptions={listarBodegas}
                                    component={AsyncSelectField}
                                    msj="Bodega"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteConsumos}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelReporteConsumos}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalConsumo(false)}
                                >
                                    Cancelar
                                </button>
                                
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalIngresosProdDet} // Ingresos de produccion detalle
                    cambiarEstado={cambiarEstadoModalIngresosProdDet}
                    titulo="Ingresos de produccion detalle"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="serie"
                                    placeholder="Serie..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarSeriesProd}
                                    msj="Producto"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteIngresosProdDetalle}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalIngresosProdDet(false)}
                                    >
                                    Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalControlCJ} //Control cajas por piloto
                    cambiarEstado={cambiarEstadoModalControlCJ}
                    titulo="Control de cajas por piloto"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                />
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="piloto"
                                    placeholder="Piloto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarPilotos}
                                    msj="Piloto"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteControlCajasPiloto}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelReporteControlCajasPiloto}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalProdD(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalKardexCajas} //kardex de cajas
                    cambiarEstado={cambiarEstadoModalKardexCajas}
                    titulo="Kardex de cajas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="insumo"
                                    placeholder="Insumo..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarInsumos}
                                    msj="Insumo"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteKardexCajas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteExistenciaCajasExcel}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalKardexCajas(false)}
                                >
                                    Cancelar
                                </button>
                                
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalNotasDeCobro} // Listado Notas de cobro
                    cambiarEstado={cambiarEstadoModalNotasDeCobro}
                    titulo="Listado de notas de cobro"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="serie"
                                    placeholder="Serie..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarSeriesNotaCobro}
                                    msj="Serie"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoNotasDeCobro}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelListadoNotasDeCobro}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalNotasDeCobro(false)}
                                    >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalExistenciaCajas} //existencia de cajas
                    cambiarEstado={cambiarEstadoModalExistenciaCajas}
                    titulo="Existencia de cajas"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mt-2 btn-primary'
                                        onClick={reporteExistenciaCajas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mt-2 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalExistenciaCajas(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalCXCDetalleEnvio} //CXC detallado
                    cambiarEstado={cambiarEstadoModalCXCDetalleEnvio}
                    titulo="CXC detalle por envío"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                {monedas &&
                                    <div className="form-floating">
                                        <Field
                                            autoFocus={autoFocus}
                                            name="moneda"
                                            options={monedas}
                                            dato={0}
                                            component={SelectField}
                                            className="form-select"
                                            msj="Moneda"
                                        />
                                    </div>
                                }
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="cliente"
                                    placeholder="Cliente..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarClientes}
                                    msj="Cliente"
                                />
                            </div>
                            <div className='col-6 mb-2'>
                                <Field
                                    name="fechaFinal"
                                    component={renderDayPickerB}
                                    msj="Fecha al"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mt-2 btn-primary'
                                        onClick={reporteCXCDetalleEnvio}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mt-2 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalCXCDetalleEnvio(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalCostoLibra} //Costo por libra
                    cambiarEstado={cambiarEstadoModalCostoLibra}
                    titulo="Calculo costo por libra"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="anio"
                                    placeholder="Año"
                                    className="form-control"
                                    component={renderNumber}
                                    onChange={handleOnChange}
                                    msj="Año"
                                />
                            </div>
                            <div className='col-6 mb-2'>
                                {listaPeriodo &&
                                    <Field
                                        name="periodo"
                                        options={listaPeriodo}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Periodos"
                                    />
                                }
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mt-2 btn-primary'
                                        onClick={reporteCostoLibra}
                                    >
                                    Generar excel
                                    </button>
                                    <button
                                        className='btn mr-2 mt-2 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalCostoLibra(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalVentaFase} //Ventas por fase
                    cambiarEstado={cambiarEstadoModalVentaFase}
                    titulo="Ventas por producto"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='row'>
                                <div className='col-6 mb-2'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="fechaInicial"
                                        component={renderDayPickerB}
                                        msj="Fecha del"
                                    />
                                </div>
                                <div className='col-6 mb-2'>
                                    <Field
                                        name="fechaFinal"
                                        component={renderDayPickerB}
                                        msj="Fecha al"
                                    />
                                </div>
                            </div>
                            <div className='col-12 mb-2'>
                                <Field
                                    name="producto"
                                    placeholder="Producto..."
                                    className="form-control-find"
                                    component={AsyncSelectField}
                                    loadOptions={listarProductos}
                                    msj="Producto"
                                />
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={() => reporteVentaFase()}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalVentaFase(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal> 
            </div>
        </div>
    );
}

InformesForm.propTypes = {
    loader: PropTypes.bool,
    modulo: PropTypes.object,
    reporteKardexValorizado: PropTypes.func,
    listarProductos: PropTypes.object,
};

export default reduxForm({
    form: 'FormInformesPrd',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
