import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
  } from "../../Utils/renderField/renderField";

function CuentaContable(props) {
    const { handleSubmit, crear, modulo } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar cuenta contable' : 'Crear cuenta contable';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Cuentas contables'
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return(
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <form onSubmit={handleSubmit}>
                <br />
                <div className="mb-4 card card-small text-white bg-dark mb-3">
                    <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                        <div className="d-flex flex-column flex-1 mx-4">
                            <div className="row mb-2">
                                <div className='col-4'>
                                    <Field
                                        autoFocus={autoFocus}
                                        name="cuenta"
                                        placeholder="Número de cuenta"
                                        className="form-control"
                                        component={renderField}
                                        msj="Número de cuenta"
                                        disabled={disabled}
                                    />
                                </div>                
                                <div className='col-6'>
                                    <Field
                                        name="nombre"
                                        placeholder="Nombre cuenta"
                                        className="form-control"
                                        component={renderField}
                                        msj="Nombre cuenta"
                                        disabled={disabled}
                                    />
                                </div>    
                            </div>
                            <div className='d-flex flex-row mt-3'>
                                {disabled == false &&
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        type='submit'
                                    >
                                        {editar ? 'Modificar' : 'Guardar'}
                                    </button>
                                }
                                <a
                                    href = {`/#/visitador_medico/${id_emp[5]}/cuenta_contable`}
                                    className='btn btn-secondary mb-3'
                                >
                                Regresar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
CuentaContable.propTypes = {
    handleSubmit: PropTypes.func,
    crear: PropTypes.bool,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormCuentasContablesVM',
})(CuentaContable);
