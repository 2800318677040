import React, { Component } from 'react';
import Formulario from './InformesForm';

class Informes extends Component{
    componentDidMount = () => {
        const { getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        getModulo(modulo[5],modulo[4]);
    }
    render(){
        const { loader, reporteLibroCompras, excelLibroCompras, 
            modulo, excelDetallePoliza, reporteListadoLiquidaciones,
            reporteFacturas, excelFacturas, reporteFacturasInv,
            excelFacturasInversion, excelDetallePolizaInversion } = this.props;
        return(
            <div>
                <Formulario
                    reporteLibroCompras = {reporteLibroCompras}
                    loader = {loader}
                    excelLibroCompras = {excelLibroCompras}
                    modulo = {modulo}
                    excelDetallePoliza = {excelDetallePoliza}
                    reporteListadoLiquidaciones = {reporteListadoLiquidaciones}
                    reporteFacturas = {reporteFacturas}
                    excelFacturas = {excelFacturas}
                    reporteFacturasInv = {reporteFacturasInv}
                    excelFacturasInversion = {excelFacturasInversion}
                    excelDetallePolizaInversion = {excelDetallePolizaInversion}
                />
            </div>
        );
    }
}

export default Informes;
