import React, { useState } from 'react';
import Modal from '../components/Modal';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LoadMask from "../Utils/LoadMask/LoadMask";
import NavbarModulo from '../components/NavbarModulo';

import {
    renderNumber,
    SelectField,
} from "../Utils/renderField/renderField";

const meses = [
    {
        label: 'ENERO',
        value: '01'
      
    },
    {
        label: 'FEBRERO',
        value: '02'
    },
    {
        label: 'MARZO',
        value: '03',
    },
    {
        label: 'ABRIL',
        value: '04',
    },
    {
        label: 'MAYO',
        value: '05',
    },
    {
        label: 'JUNIO',
        value: '06',
    },
    {
        label: 'JULIO',
        value: '07',
    },
    {
        label: 'AGOSTO',
        value: '08',
    },
    {
        label: 'SEPTIEMBRE',
        value: '09',
    },
    {
        label: 'OCTUBRE',
        value: '10'
    },
    {
        label: 'NOVIEMBRE',
        value: '11',
    },
    {
        label: 'DICIEMBRE',
        value: '12',
    },
];


function InformesForm(props) {
    const {
        modulo,
        excelDetallePoliza,
        reporteLibroCompras,
        loader,
        excelLibroCompras,
        reporteListadoLiquidaciones,
        reporteFacturas,
        excelFacturas,
        reporteFacturasInv,
        excelFacturasInversion,
        excelDetallePolizaInversion
    } = props;

    
    const [estadoModalLibro, cambiarEstadoModalLibro] = useState(false);
    const [estadoModalFactura, cambiarEstadoModalFactura] = useState(false);
    const [estadoModalFacturaInv, cambiarEstadoModalFacturaInv] = useState(false);
    const [estadoModalPoliza, cambiarEstadoModalPoliza] = useState(false);
    const [estadoModalPolizaInv, cambiarEstadoModalPolizaInv] = useState(false);
    const [folioLC,  cambiarFolioLC] = useState(1);
    const [estadoModalListado, cambiarEstadoModalListado] = useState(false);
    
    localStorage.setItem('folioLVM', folioLC);
    const handleChangeLC = e => {
        localStorage.setItem('folioVM', e.target.value);
        cambiarFolioLC(e.target.value);
    }
    let autoFocus = true;
    let titulo = 'Informes';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
        
            <div className="container">
                <br/>
                <h4 className='mt-1' style={{ color: "white" }}>Informes</h4>
                <div className="row">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalFactura(!estadoModalFactura)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Facturas de liquidación
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalFacturaInv(!estadoModalFacturaInv)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Facturas de inversión
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalPoliza(!estadoModalPoliza)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Detalle de póliza gastos
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalPolizaInv(!estadoModalPolizaInv)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Detalle de póliza inversion
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalListado(!estadoModalListado)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Listado de liquidaciones
                            </button>
                        </div>
                    </div>
                    <div className="col-xxl-3">
                        <div className="list-group" id="list-tab" role="tablist">
                            <button class="list-group-item list-group-item-action"
                                id="list-home-list"
                                onClick={() => cambiarEstadoModalLibro(!estadoModalLibro)}
                                data-bs-toggle="list"
                                role="tab"
                                href={`/#/visitador_medico/${id_emp[5]}/informes`}
                                aria-controls="list-home">Libro de Compras
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    estado={estadoModalFactura} //Facturas
                    cambiarEstado={cambiarEstadoModalFactura}
                    titulo="Facturas de liquidación"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteFacturas}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelFacturas}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalFactura(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalFacturaInv} //Facturas de inversion
                    cambiarEstado={cambiarEstadoModalFacturaInv}
                    titulo="Facturas de inversión"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteFacturasInv}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelFacturasInversion}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalFacturaInv(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalLibro} //libro compras
                    cambiarEstado={cambiarEstadoModalLibro}
                    titulo="Libro de compras"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteLibroCompras}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelLibroCompras}
                                    >
                                    Exportar Excel
                                    </button>
                                </div>
                                <button
                                    className='btn mr-2 mb-3 btn btn-secondary'
                                    onClick={() => cambiarEstadoModalLibro(false)}
                                >
                                    Cancelar
                                </button>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalPoliza} //Detalle de poliza
                    cambiarEstado={cambiarEstadoModalPoliza}
                    titulo="Detalle de poliza"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelDetallePoliza}
                                    >
                                    Exportar Excel
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalPoliza(false)}
                                    >
                                    Cancelar
                                    </button>    
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalPolizaInv} //Detalle de poliza inversion
                    cambiarEstado={cambiarEstadoModalPolizaInv}
                    titulo="Detalle de poliza inversión"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={excelDetallePolizaInversion}
                                    >
                                    Exportar Excel
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalPolizaInv(false)}
                                    >
                                    Cancelar
                                    </button>    
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
                <Modal
                    estado={estadoModalListado} //Listado de liquidaciones
                    cambiarEstado={cambiarEstadoModalListado}
                    titulo="Listado de liquidaciones"
                    botonCerrar="true"
                    >
                        <Contenido>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        autoFocus={autoFocus}
                                        name="mes"
                                        options={meses}
                                        component={SelectField}
                                        className="form-select"
                                        msj="Mes"
                                    />
                                </div>
                            </div>
                            <div className='col-6 mb-2'>
                                <div className="form-floating">
                                    <Field
                                        name="year"
                                        component={renderNumber}
                                        className="form-control"
                                        msj="Año"
                                    />
                                </div>
                            </div>
                        </Contenido>
                        <LoadMask loading={loader} dark>
                            <Contenido>
                                <div className='row'>
                                    <button
                                        className='btn mr-2 mb-3 btn-primary'
                                        onClick={reporteListadoLiquidaciones}
                                    >
                                    Generar Reporte
                                    </button>
                                    <button
                                        className='btn mr-2 mb-3 btn btn-secondary'
                                        onClick={() => cambiarEstadoModalListado(false)}
                                    >
                                    Cancelar
                                    </button>    
                                </div>
                            </Contenido>
                        </LoadMask>
                </Modal>
            </div>
        </div>
    );

}

InformesForm.propTypes = {
    reporteLibroCompras: PropTypes.func,
    loader: PropTypes.bool,
    excelLibroCompras: PropTypes.func,
    excelDetallePoliza: PropTypes.func,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormInformesVM',

})(InformesForm);


const Contenido = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`;
