import React, { useEffect } from "react";
import DataTableWSearch from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import * as Fa from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const writeSuccessMsg = "Ingreso Eliminado";

function IngresosList(props) {
    const { data, listarIngresos, parametrosEmpresa,
        reporteRecepcionDeVehiculos, history, datos } =
        props;

    const eliminar = (ingresoId) => {
        let ruta = window.location.href;
        let id_emp = ruta.split(`/`);
        const formData = {
            empresa: id_emp[5],
        };
        return api
            .eliminar(`auto_ventas/ingresos/${ingresoId}`, formData)
            .then(() => {
                NotificationManager.success(writeSuccessMsg, "Exito", 3000);
                listarIngresos();
            })
            .catch((error) => {
                NotificationManager.error(error ? error : "", "Error", 2000);
            });
    };

    useEffect(() => {
        listarIngresos();
        parametrosEmpresa();
    }, []);
    
    const columns = [
        {
            name: "Codigo",
            selector: (row) => row.codigo,
            sortable: true,
        },
        {
            name: "Marca",
            selector: (row) => row.marca.label,
            sortable: true,
        },
        {
            name: "Linea",
            selector: (row) => row.linea.label,
            sortable: true,
        },
        {
            name: "Modelo",
            selector: (row) => row.modelo,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    title="Editar ingreso"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    title="Ver ingreso"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(210, 100%, 90%)"
                    background="hsla(210, 100%, 50%, 0.8)"
                    title="Ingresar otros gastos"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/otro_gasto/${row.id}`
                        );
                    }}
                >
                    <Fa.FaCashRegister />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsla(162, 100%, 50%, 1)"
                    background="hsla(162, 100%, 50%, 0.2)"
                    title="Documento de recepcion de vehiculos"
                    onPress={(e) => {
                        reporteRecepcionDeVehiculos(row.id);
                    }}
                >
                    <Fa.FaPrint />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsla(0, 100%, 50%, 1)"
                    background="hsla(0, 100%, 50%, 0.2)"
                    title="Eliminar"
                    onPress={(e) => {
                        eliminar(row.id);
                    }}
                >
                    <Fa.FaTrash />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const columnsA = [
        {
            name: "Codigo",
            selector: (row) => row.codigo,
            sortable: true,
        },
        {
            name: "Marca",
            selector: (row) => row.marca.label,
            sortable: true,
        },
        {
            name: "Linea",
            selector: (row) => row.linea.label,
            sortable: true,
        },
        {
            name: "Modelo",
            selector: (row) => row.modelo,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    title="Editar ingreso"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    title="Ver ingreso"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsla(162, 100%, 50%, 1)"
                    background="hsla(162, 100%, 50%, 0.2)"
                    title="Documento de recepcion de vehiculos"
                    onPress={(e) => {
                        reporteRecepcionDeVehiculos(row.id);
                    }}
                >
                    <Fa.FaPrint />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsla(0, 100%, 50%, 1)"
                    background="hsla(0, 100%, 50%, 0.2)"
                    title="Eliminar"
                    onPress={(e) => {
                        eliminar(row.id);
                    }}
                >
                    <Fa.FaTrash />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Ingresos de vehiculos
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    // BtnColor="#00cb03"
                    style={{ width: "150px", height: "40px" }}
                    onClick={() => {
                        location.href += "/crear";
                    }}
                >
                    Crear ingreso
                </GenBtn>
            </div>
            <DataTableWSearch
                columns={datos.proveedor_autoventa === 'S' ? columns: columnsA}
                data={data}
                fieldToFilter="codigo"
                filterPlaceHolder="Buscar por codigo"
            />
        </React.Fragment>
    );
}

export default withRouter(IngresosList);
