import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DataTable from "../../components/DataTableW";
import * as Fa from "react-icons/fa";
import * as yup from 'yup';
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import {
  FicInput,
  FicTextArea,
  FicNumber,
} from "../../Utils/FormInputComponents/index";
const schema = yup.object().shape({
  monto: yup
    .number()
    .typeError('Debe ser un número válido')
    .positive('El monto debe ser positivo')
    .required('Campo requerido'),
  descripcion: yup.string().max(150, 'Máximo 150 caracteres'),
});

function Comisiones(props) {
  const {
    history,
    guardarComisiones
  } = props;
  const urlParams = props.match.params;
  const empresa = urlParams.empresa;
  const [nombreV, setNombreV] = useState("");
  const [comisionesRegistradas, setComisionRegistrada] = useState([]);
  
  const { 
    control, 
    handleSubmit, 
    reset,
    formState: { } 
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nombre: "",
      monto: "",
      descripcion: "",
    }
  });

  // Cargar datos iniciales
  useEffect(() => {
    const { datosVendedor, listarComisiones } = props;
    let ruta = window.location.href;
    let id_auto = ruta.split('/');
    let datosV = datosVendedor(id_auto[8],id_auto[5]);
    let comisiones = listarComisiones(id_auto[8],id_auto[5]);
    let listaComision = [];
    comisiones.then(c => {
      c.forEach(item => {
        let comisionI = {}
        comisionI["id"] = item.id
        comisionI["monto"] = parseFloat(item.monto)
        comisionI["descripcion"] = item.descripcion
        listaComision.push(comisionI);
      })
      setComisionRegistrada([...comisionesRegistradas, ...listaComision]);
    })
    datosV.then(d => { 
      setNombreV(d.nombre)
    });
  }, []);

  const agregarComision = (data) => {
    const nuevaComision = {
      id: Date.now(),
      monto: data.monto,
      descripcion: data.descripcion,
    };
    
    setComisionRegistrada([...comisionesRegistradas, nuevaComision]);
    reset();
  };

  const eliminarGasto = (id) => {
    setComisionRegistrada(comisionesRegistradas.filter(g => g.id !== id));
  };

  const columns = [
    {
      name: "Descripcion",
      selector: (row) => row.descripcion,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) => {
        const monto = parseFloat(row.monto); 
        if (isNaN(monto)) {
          return "0.00";
        }
        return monto.toLocaleString('es-GT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },
      right: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <GenBtn
          textColor="hsla(0, 100%, 50%, 1)"
          background="hsla(0, 100%, 50%, 0.2)"
          title="Eliminar"
          onPress={() => eliminarGasto(row.id)}
        >
          <Fa.FaTrash />
        </GenBtn>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  return (
    <React.Fragment>
      <br />
      <h3
        className="p-2  title rounded shadow"
        style={{
          background:
              "linear-gradient(to top right, #790021, #2d2d2d)",
        }}
      >
        Comisiones - Vendedores
      </h3>
      <br />
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Datos del Vendedor
      </h4>
      <div className="inputs-container">
        <FicInput
          label="Nombre"
          name="nombre"
          placeholder={`${nombreV}`}
          control={control}
          isReadOnly={true}
        />
      </div>
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Comisiones
      </h4>
      <form onSubmit={handleSubmit(agregarComision)}>
        <div className="inputs-container">
          <FicNumber
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
            label="Monto"
            name="monto"
            control={control}
          />    
          <FicTextArea
            label="Descripción"
            name="descripcion"
            control={control}
          />
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center mb-2" 
          style={{ height: "80px" }}
        >
        <GenBtn
          type="submit"
          BtnColor="#1976D2"
          style={{ 
            width: "200px", 
            height: "40px", 
            backgroundColor: "#1976D2", 
            color: "#FFFFFF", 
            border: "none", 
            borderRadius: "4px", 
            cursor: "pointer", 
            transition: "background-color 0.3s ease", 
            display: "flex", 
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Agregar comisión 
      </GenBtn>
      </div>
      </form>
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Detalle de comisiones
      </h4>
      <DataTable
        columns={columns}
        data={comisionesRegistradas}
      />
      <br/>
      <div className="d-flex flex-row justify-content-start mb-2">
        <GenBtn
          onPress={() => {
            guardarComisiones(comisionesRegistradas);
          }}
          autofocus="autofocus"
        >
          Guardar comisiones
        </GenBtn>
        <GenBtn
          BtnColor="#787878"
          style={{ width: "100px", height: "40px", marginLeft: "16px"}}
          onPress={(e) => {
            history.push(`/auto_ventas/${empresa}/vendedores`);
          }}
        >
          Regresar
        </GenBtn>
      </div>
      <br/>
    </React.Fragment>
  );
}

export default  withRouter(Comisiones);
