import React, { useMemo } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./DataTableW.css";

const tableStyles = {
    table: {
        style: {
        background: "#1e1e1e",
        },
    },
    tableWrapper: {
        style: {
        borderRadius: "7px 7px 0 0",
        },
    },
    responsiveWrapper: {
        style: {
        borderRadius: "7px 7px 0 0",
        border: "1px solid black",
        borderBottom: "none",
        },
    },
    rows: {
        style: {
        borderStyle: "none",
        },
    },
    head: {
        style: {
        background: "#431824",
        borderBottom: "1px solid #bd0034",
        color: "white",
        },
    },
    pagination: {
        style: {
        border: "1px solid black",
        borderTop: "1px solid #bd0034",
        background: "#1e1e1e",
        borderRadius: "0 0 7px 7px",
        },
    },
    expanderRow: {
        style: {
        borderBottom: "1px dashed #bd0034",
        background: "#121212",
        },
    },
};

const sortIcon = <ArrowDropDownIcon />;

createTheme(
    "blackDivider",
    {
        background: {
        default: "hsla(0, 0%, 0%, 0)",
        },
        divider: {
        default: "#000000",
        },
        subHeader: {
        style: {
            backgroundColor: "hsla(0, 0%, 0%, 0)",
        },
        default: "hsla(0, 0%, 0%, 0)",
        },
    },
    "dark"
);

function DataTableW({ data, ...props }) {
    const tableData = data ? data : [];

    // Calcular la suma de los montos
    const totalMonto = useMemo(() => {
        const total = tableData.reduce((total, row) => total + (parseFloat(row.monto) || 0), 0);
        return total.toLocaleString('es-GT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }, [tableData]);

    return (
        <div>
            <DataTable
                pagination
                sortIcon={sortIcon}
                theme="blackDivider"
                data={tableData}
                paginationPerPage={25}
                paginationComponentOptions={{
                    rowsPerPageText: "Filas por página",
                    rangeSeparatorText: "de",
                }}
                noDataComponent="No hay datos"
                customStyles={tableStyles}
                {...props}
            />
            <div style={{
                padding: "10px",
                backgroundColor: "#431824",
                borderTop: "1px solid #bd0034",
                color: "white",
                textAlign: "right",
                fontWeight: "bold"
            }}>
                Total: {totalMonto}
            </div>
        </div>
  );
}

export default DataTableW;