import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PROVEEDOR = 'PROVEEDOR';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const listarProveedoresImp = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/proveedor', params).then((response)=>{
        dispatch({ type: PROVEEDOR, datos: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar proveedores',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroProveedor = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormProveedorVM.values;
    if (data === undefined){
        Swal.fire({
            title: 'Proveedor',
            text: 'Debe de seleccionar proveedor',
            type: 'error',
        });
    }else{
        if (data.proveedor === undefined || data.proveedor === ''){
            Swal.fire({
                title: 'Proveedor',
                text: 'Debe de seleccionar proveedor',
                type: 'error',
            });
        }else{
            let id = data.proveedor.value;
            const formData = {
                id: id,
                porcentaje: data.porcentaje,
                empresa: id_emp[5],
            }
            dispatch(setLoader(true));
            api.put(`/visitador_medico/proveedor/${id}/`, formData).then(() => {
                NotificationManager.success(
                    'Proveedor agregado',
                    'Exito',
                    3000
                );
                dispatch(listarProveedoresImp());
                dispatch(initializeForm('FormProveedorVM', '' ));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const listarProveedores = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/visitador_medico/proveedor/proveedores_importacion/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                list_cuentas.push({
                    value: item.id,
                    label: item.identificador + ' -- ' + item.nombre
                })
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

const eliminarProveedor = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/visitador_medico/proveedor/${id}`).then(() => {
        NotificationManager.success(
            'Proveedor borrado correctamente',
            'Exito',
            3000
        );
        dispatch(listarProveedoresImp());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    getModulo,
    eliminarProveedor,
    registroProveedor,
    listarProveedoresImp,
    listarProveedores
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PROVEEDOR]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    datos: []
};

export default handleActions(reducers, initialState)
 