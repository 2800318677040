
import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const PERIODOS_LIST = 'PERIODOS_LIST';
const LOADER = 'LOADER';
const PERIODO_D = 'PERIODO_D';

export const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const periodosList = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return api.get(`/periodos/periodo_empresa/?id=${id_emp[5]}`).then((data)=>{
        const periodo = [];
        data.forEach(item=>{
            periodo.push({
                value: item.id,
                label: item.periodo
            })
        })
        dispatch({ type: PERIODOS_LIST, periodoL: periodo });
    }).catch(error=>{
        NotificationManager.error(
            'Ocurrió un error al listar los periodos',
            'Error',
            0
        );
    })
}

export const calcularCosto = (periodo, ruta) => (dispatch) => {
    
    let id_emp = ruta.split('/');
    let idEmpresa = id_emp[5];
    const params = { periodo, idEmpresa };
    
    dispatch(setLoader(true));
    api.get('/calculo_costo/calcularCosto', params).then(() => {
        NotificationManager.success(
            'Calculo de costo terminado',
            'Exito',
            0
        );
    }).catch((error) => {
        NotificationManager.error(
            error.body.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leerPeriodoD = (id_periodo) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}


export const actions = {
    periodosList,
    calcularCosto,
    leerPeriodoD
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PERIODOS_LIST]: (state, { periodoL }) => {
        return {
            ...state,
            periodoL,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
};

export const initialState = {
    loader: false,
    periodoL: [],
    periodoD: [],
};

export default handleActions(reducers, initialState)