import { handleActions } from 'redux-actions';
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { push } from "react-router-redux";
import Swal from 'sweetalert2';

const LOADER = 'LOADER';
const MODULO = 'MODULO';
const CUENTAS = 'CUENTAS';
const CUENTAS_CONFIG = 'CUENTAS_CONFIG';

const setLoader = loader => ({
    type: LOADER,
    loader,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/cuenta_contable', params).then((response)=>{
        dispatch({ type: CUENTAS, data: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas contables',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormCuentasContablesVM.values;
    if (data.cuenta === undefined || data.numero === ''){
        Swal.fire({
            title: 'Cuentas contables',
            text: 'Debe de ingresar número de cuenta',
            type: 'error',
        });
    }else{
        if (data.nombre === undefined || data.nombre === ''){
            Swal.fire({
                title: 'Cuentas contables',
                text: 'Debe de ingresar nombre cuenta',
                type: 'error',
            });
        }else{
            const formData = {
                cuenta: data.cuenta,
                nombre: data.nombre,
                empresa: id_emp[5],
            }
            dispatch(setLoader(true));
            api.post('/visitador_medico/cuenta_contable/', formData).then((response) => {
                NotificationManager.success(
                    'Cuenta contable agregada',
                    'Exito',
                    3000
                );
                response.cuenta = ''
                response.nombre = ''
                dispatch(initializeForm('FormCuentasContablesVM', response ));
            }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
            }).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }
}

export const modificarCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormCuentasContablesVM.values;
    const id = datos.id;
    const formData = {
        id: id,
        cuenta: datos.cuenta,
        nombre: datos.nombre,
    }
    dispatch(setLoader(true));
    api.put(`/visitador_medico/cuenta_contable/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Cuenta contable modificada correctamente',
            'Exito',
            3000
        );
        let ruta = `/visitador_medico/${id_emp[5]}/cuenta_contable`
        dispatch(push(ruta));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error al modificar cuenta contable',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/visitador_medico/cuenta_contable/${id}`).then((response) => {
        dispatch(initializeForm('FormCuentasContablesVM', response ));
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al consultar cuenta contable',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarCuentasConfig = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/config_cuenta', params).then((response)=>{
        dispatch({ type: CUENTAS_CONFIG, datos: response });
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al listar las cuentas contables',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroConfigCuenta = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormParmetroCuentasContablesVM.values;
    const formData = {
        descripcion_cuenta: data.tipo,
        cuenta: data.cuenta.value,
        empresa: id_emp[5],
    }
    dispatch(setLoader(true));
    api.post('/visitador_medico/config_cuenta/', formData).then(() => {
        NotificationManager.success(
            'Cuenta agregada',
            'Exito',
            3000
        );
        dispatch(listarCuentasConfig());
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const listarCuentasContables = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/visitador_medico/cuenta_contable/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                list_cuentas.push({
                    value: item.id,
                    label: item.cuenta + ' -- ' + item.nombre
                })
            })
        }
        return list_cuentas;
    }).catch((error)=>{
        return [];
    })
}

const eliminarCuenta = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`/visitador_medico/config_cuenta/${id}`).then(() => {
        NotificationManager.success(
            'Cuenta borrada correctamente',
            'Exito',
            3000
        );
        dispatch(listarCuentasConfig());
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const actions = {
    getModulo,
    registroCuenta,
    modificarCuenta,
    listar,
    eliminarCuenta,
    leer,
    registroConfigCuenta,
    listarCuentasConfig,
    listarCuentasContables
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [CUENTAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [CUENTAS_CONFIG]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
    datos: []
};

export default handleActions(reducers, initialState)
 