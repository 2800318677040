import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

export const datosVendedor = (ingresoId,empresaId) => () => {
    let id = empresaId
    let action = "ver"
    const params = { id, action }
    return api.get(`auto_ventas/vendedores/${ingresoId}`, params).then((response) => {
        return response
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const listarComisiones = (vendedorId,empresaId) => () => {
    let id = empresaId
    let vendedor = vendedorId
    const params = { id, vendedor }
    return api.get('auto_ventas/comisiones', params).then((response) => {
        return response
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const guardarComisiones = (data) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        id_vendedor: id_emp[8],
        empresa: id_emp[5],
        detalleComision: data
    }
    api.post('/auto_ventas/comisiones/', formData).then(() => {
        NotificationManager.success(
            'Gastos guardados',
            'Exito',
            3000
        );
        let ruta = `/auto_ventas/${id_emp[5]}/vendedores`
        dispatch(push(ruta));
    })
    .catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
    
}


export const actions = {
    datosVendedor,
    guardarComisiones,
    listarComisiones
};

export const reducers = {
};

export const initialState = {
    data: [],

};

export default handleActions(reducers, initialState)
