import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/otro_gasto';
import OtrosGastos from './OtroGastoForm';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_otro_gasto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p,)(OtrosGastos);
