import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/gastos_inversion';
import GastosInvList from './GastosInvList';


const ms2p = (state) => {
  return {
    ...state.vm_gastos_inversion,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(GastosInvList);

