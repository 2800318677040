import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

class LineaPromocionalList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: true
    };

    componentDidMount = () => {
        const { listar, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listar();
        getModulo(modulo[5],modulo[4]);
    }
    render(){
        const { data, loader, modulo } = this.props;
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let titulo = '';
        return(
            <React.Fragment>
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Lineas promocionales</h3>
                <br />
                <div className="d-flex flex-row justify-content-start mb-2">
                    <a
                        href={`/#/visitador_medico/${id_emp[5]}/linea_promocional/crear`}
                        className='btn btn-primary'
                    >
                        Crear linea promocional
                    </a>
                </div>
                {data &&
                    <Grid 
                        hover 
                        striped 
                        data={data} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="codigo"
                            headerAlign="center"
                            dataAlign="right"
                            width="100"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Codigo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="descripcion"
                            headerAlign="center"
                            width="200"
                            dataSort
                            thStyle={
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Linea promocional
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            width="100"
                            dataAlign="center"
                            dataSort
                            dataFormat={standardActions({ 
                                editar: "linea_promocional", 
                                ver: "linea_promocional",
                            })} 
                            
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default LineaPromocionalList;
