import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/auto_ventas/comisiones';
import Comisiones from './ComisionesForm';


const ms2p = (state) => {
  return {
    ...state.auto_ventas_comisiones,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p,)(Comisiones);
