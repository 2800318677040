import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';

const LISTADO_LINEA = 'LISTADO_LINEA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let params = { id };
    dispatch(setLoader(true));
    api.get('/visitador_medico/linea_promocional', params).then((response)=>{
        dispatch({ type: LISTADO_LINEA, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar lineas promocionales',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const registroLinea = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormLineaPromocionalVM.values;
    let id_empresa = id_emp[5]
    if (data === undefined){
        Swal.fire({
            title: 'Linea promocional',
            text: 'Debe de ingresar descripción',
            type: 'error',
        })    
    }else{
        if (data.descripcion === undefined || data.descripcion === ''){
            Swal.fire({
                title: 'Linea promocional',
                text: 'Debe de ingresar descripción',
                type: 'error',
            })    
        }else{
            if (data.cuenta === undefined || data.cuenta === ''){
                Swal.fire({
                    title: 'Linea promocional',
                    text: 'Debe de seleccionar cuenta contable',
                    type: 'error',
                })    
            }else{
                const formData = {
                    descripcion: data.descripcion,
                    cuenta: data.cuenta.value,
                    empresa: id_empresa
                }
                dispatch(setLoader(true));
                api.post('/visitador_medico/linea_promocional/', formData).then(() => {
                    NotificationManager.success(
                        'Linea promocional creado',
                        'Exito',
                        3000
                    );
                    let ruta = `/visitador_medico/${id_emp[5]}/linea_promocional`
                    dispatch(push(ruta));
                }).catch((error) => {
                NotificationManager.error(
                    error.msj,
                    'Error',
                    2000
                );
                }).finally(() => {
                    dispatch(setLoader(false));
                });
            }
        }
    }
}

export const modificarLinea = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const datos = getStore().form.FormLineaPromocionalVM.values;
    const id = datos.id;
    let cuentaContable = ''
    let cuenta = datos.cuenta_mod;
    if (cuenta === undefined){
        cuentaContable = datos.cuenta;
    }else{
        cuentaContable = datos.cuenta_mod.value;
    }
    const formData = {
        id: id,
        descripcion: datos.descripcion,
        cuenta: cuentaContable
    }
    dispatch(setLoader(true));
    api.put(`/visitador_medico/linea_promocional/${id}/`, formData).then(() => {
        NotificationManager.success(
            'Linea promocional modificado correctamente',
            'Exito',
            3000
        );
        let ruta = `/visitador_medico/${id_emp[5]}/linea_promocional`
        dispatch(push(ruta));
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al modificar linea promocional',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/visitador_medico/linea_promocional/${id}`).then((response) => {
        dispatch(initializeForm('FormLineaPromocionalVM', response ));
    }).catch(()=>{
        NotificationManager.error(
            'Ocurrió un error al consultar linea promocional',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 400){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const listarCuentasContables = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id, search}
    return api.get('/visitador_medico/cuenta_contable/',params).then(data=>{
        const list_cuentas = [];
        if(data){
            data.forEach(item=>{
                list_cuentas.push({
                    value: item.id,
                    label: item.cuenta + ' -- ' + item.nombre
                })
            })
        }
        return list_cuentas;
    }).catch(()=>{
        return [];
    })
}


export const actions = {
    listar,
    registroLinea,
    modificarLinea,
    leer,
    getModulo,
    listarCuentasContables
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_LINEA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
};

export const initialState = {
    loader: false,
    modulo: [],
    data: [],
};

export default handleActions(reducers, initialState)
 