import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import TablaVerDetalle from './OtrosGastosFacturas';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    renderCurrencyFloat
} from "../../Utils/renderField/renderField";


function OtrosGastosDetalleForm(props) {
    const { modulo, bloqueo, detalleDoc } = props;

    let titulo = 'Ver otros gastos inversión';
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='row mb-2'>
                            <div className='col-6'>
                                <Field
                                    name="nombre"
                                    placeholder="Proveedor"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Visitador"
                                />
                            </div>
                            <div className='col-6'>
                                <Field
                                    name="puesto"
                                    placeholder="Puesto"
                                    className="form-control"
                                    component={renderField}
                                    disabled={true}
                                    msj="Puesto"
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-10'>
                                <Field
                                    name="descripcion"
                                    placeholder="Descripcion"
                                    component={renderField}
                                    className="form-control"
                                    disabled={bloqueo}
                                    msj="Descripcion"
                                />
                            </div>
                            <div className='col-2'>
                                <Field
                                    name="total"
                                    placeholder="Total"
                                    className="form-control"
                                    component={renderCurrencyFloat}
                                    disabled={true}
                                    msj="Total"
                                />
                            </div>
                        </div>
                        <div>
                            <h5 className="p-2 bg-secondary title mt-1">Facturas</h5>
                            <TablaVerDetalle
                                detalle={detalleDoc}
                            />
                        </div>
                        <div className='d-flex flex-row mt-3'>
                            <a
                                href={`/#/visitador_medico/${id_emp[5]}/otros_gastos_inversion/${id_emp[7]}`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

OtrosGastosDetalleForm.propTypes = {
    lectura: PropTypes.object,
};

export default reduxForm({
    form: 'FormOtrosGastosInvDetalle',
})(OtrosGastosDetalleForm);

