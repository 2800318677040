import React, { useEffect, useRef, useState } from "react";
import DataTableWSearch from "../../components/DataTableWSearch";
import GenBtn from "../../components/GenBtn";
import * as Fa from "react-icons/fa";
import { withRouter } from "react-router-dom";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import FormDialogModal from "../../components/FormDialogModal";
import {
    FicDatePickerSI,
    FicInput,
} from "../../Utils/FormInputComponents/index";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "styled-components";

const FechaDeBajaFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.8rem;
    padding: 3.5rem;
`;

const NombreVendedor = styled.div`
    align-self: center;
    color: #ffc800;
`;

const schema = yup.object({
    fecha_de_baja: yup.string().required("Ingrese la fecha"),
    vendedor: yup.string().required("El vendedor es requerido"),
});

function IngresosList(props) {
    const { listarVendedores, darDeBajaVendedor, history, datos, parametrosEmpresa } = props;
    const [loadingState, setLoadingState] = useState(true);
    const [data, setData] = useState([]);
    const darDeBajaDialogRef = useRef(null);
    const [vendedorSeleccionado, setVendedorSeleccionado] = useState({
        id: "",
        nombre: "",
    });

    const {
        handleSubmit,
        setValue,
        control,
        formState: {  },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        defaultValues: { fecha_de_baja: "", vendedor: "" },
    });

    async function fetchData() {
        setData(await listarVendedores());
        setLoadingState(false);
    }

    useEffect(() => {
        fetchData();
        parametrosEmpresa();
    }, []);

    useEffect(() => {
        setValue("vendedor", vendedorSeleccionado.id);
    }, [vendedorSeleccionado]);

    const onSubmit = (data) => {
        darDeBajaVendedor(data).then(() => {
            fetchData();
        });
    };

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.nombre,
            sortable: true,
        },
        {
            name: "DPI",
            selector: (row) => row.dpi,
            sortable: true,
        },
        {
            name: "Telefono",
            selector: (row) => row.num_telefono,
            sortable: true,
        },
        {
            name: "Direccion",
            selector: (row) => row.direccion,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(210, 100%, 90%)"
                    background="hsla(210, 100%, 50%, 0.8)"
                    title="Ingresar comisiones"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/comisiones/${row.id}`
                        );
                    }}
                >
                    <Fa.FaCashRegister />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) =>
                row.activo ? (
                    <GenBtn
                        textColor="hsl(4, 100%, 50%)"
                        background="hsla(4, 100%, 50%, 0.2)"
                        onPress={(e) => {
                            setVendedorSeleccionado({
                                id: row.id,
                                nombre: row.nombre,
                            });
                            darDeBajaDialogRef.current.showModal();
                            // row.id
                        }}
                    >
                        <Fa.FaDoorOpen />
                    </GenBtn>
                ) : (
                    <GenBtn
                        isDisabled
                        textColor="hsl(4, 100%, 50%, 0.5)"
                        background="hsla(4, 100%, 50%, 0.1)"
                    >
                        <Fa.FaDoorOpen />
                    </GenBtn>
                ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const columnsA = [
        {
            name: "Nombre",
            selector: (row) => row.nombre,
            sortable: true,
        },
        {
            name: "DPI",
            selector: (row) => row.dpi,
            sortable: true,
        },
        {
            name: "Telefono",
            selector: (row) => row.num_telefono,
            sortable: true,
        },
        {
            name: "Direccion",
            selector: (row) => row.direccion,
            sortable: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(0, 0%, 80%)"
                    background="hsla(0, 100%, 100%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/editar/${row.id}`
                        );
                    }}
                >
                    <Fa.FaPen />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) => (
                <GenBtn
                    textColor="hsl(50, 50%, 80%)"
                    background="hsla(50, 50%, 80%, 0.2)"
                    onPress={(e) => {
                        history.push(
                            `${history.location.pathname}/ver/${row.id}`
                        );
                    }}
                >
                    <Fa.FaEye />
                </GenBtn>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            cell: (row) =>
                row.activo ? (
                    <GenBtn
                        textColor="hsl(4, 100%, 50%)"
                        background="hsla(4, 100%, 50%, 0.2)"
                        onPress={(e) => {
                            setVendedorSeleccionado({
                                id: row.id,
                                nombre: row.nombre,
                            });
                            darDeBajaDialogRef.current.showModal();
                            // row.id
                        }}
                    >
                        <Fa.FaDoorOpen />
                    </GenBtn>
                ) : (
                    <GenBtn
                        isDisabled
                        textColor="hsl(4, 100%, 50%, 0.5)"
                        background="hsla(4, 100%, 50%, 0.1)"
                    >
                        <Fa.FaDoorOpen />
                    </GenBtn>
                ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];


    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Vendedores
            </h3>
            <br />
            <div className="d-flex flex-row justify-content-start mb-2">
                <GenBtn
                    // BtnColor="#00cb03"
                    style={{ width: "200px", height: "40px" }}
                    onPress={() => {
                        location.href += "/crear";
                    }}
                >
                    Agregar vendedor
                </GenBtn>
            </div>
            <LoadMask loading={loadingState} color="#bd0034" blur={true}>
                <DataTableWSearch
                    columns={datos.proveedor_autoventa === 'S' ? columns: columnsA}
                    data={data}
                    fieldToFilter="nombre"
                    filterPlaceHolder="Buscar por nombre"
                />
            </LoadMask>

            <FormDialogModal
                title="Dar de baja?"
                dialogRef={darDeBajaDialogRef}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FechaDeBajaFormContainer>
                    <div style={{ color: "white", alignSelf: "center" }}>
                        Nombre:
                        <NombreVendedor>
                            {vendedorSeleccionado.nombre}
                        </NombreVendedor>
                    </div>
                    <FicInput
                        isDisabled
                        componentStyle={{ display: "none" }}
                        control={control}
                        name="vendedor"
                        label="Vendedor"
                    />
                    <FicDatePickerSI
                        control={control}
                        label="Fecha de baja"
                        name="fecha_de_baja"
                    />
                    <GenBtn
                        style={{ alignSelf: "center", justifySelf: "flex-end" }}
                        type="submit"
                        textColor="hsl(4, 100%, 50%)"
                        background="hsla(4, 100%, 50%, 0.2)"
                    >
                        Dar de baja
                    </GenBtn>
                </FechaDeBajaFormContainer>
            </FormDialogModal>
        </React.Fragment>
    );
}

export default withRouter(IngresosList);
