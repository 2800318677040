import React from "react";
import FormDialogModal from "../../../components/FormDialogModal";
import GenBtn from "../../../components/GenBtn";
import {
    FicComboBox,
} from "../../../Utils/FormInputComponents/index";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

const FormContent = styled.div`
    display: flex;
    overflow: visible;
    flex-direction: column;
    gap: 5px;
    min-width: 25rem;
    width: 45rem;
    height: 15rem;
    padding: 8px;
`;

const schema = yup
    .object({
        estado: yup.mixed().required("Seleccione una opcion"),
    })
    .required();

const OPCIONES = [
    { label: "TODOS", value: "TODOS" },
    { label: "ACTIVO", value: "ACTIVO" },
    { label: "VENDIDO", value: "VENDIDO" },
];

function ListadoDeAutos({ 
    dialogRef, 
    onSubmit, 
    listarVehiculos,
    ...props
}) {
    

    const defaultValues = { estado: { label: "TODOS", value: "TODOS" } };

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setFocus,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: defaultValues,
    });

    function submitFunc(data) {
        onSubmit(data);
        reset();
    }

    return (
        <FormDialogModal
            style={{ overflow: "visible" }}
            dialogRef={dialogRef}
            onSubmit={handleSubmit(submitFunc)}
            title={"Listado de costos"}
        >
            <FormContent>
                <div className="pr-3 pl-3 pb-2 pt-3 g-3">
                    <FicComboBox
                        nonAsync
                        label="Estado"
                        name="estado"
                        options={OPCIONES}
                        control={control}
                    />
                    <FicComboBox
                        componentStyle={{ minWidth: "255px" }}
                        control={control}
                        loadOptions={listarVehiculos}
                        label="Vehiculo"
                        name="vehiculo"
                        placeholder="Dejar en blanco para listar todos los vehiculos"
                    />
                    
                </div>
                <div className="d-flex justify-content-end mt-auto">
                    <GenBtn
                        type="submit"
                        style={{
                            width: "90px",
                            height: "40px",
                            marginBottom: "8px",
                        }}
                    >
                        Generar
                    </GenBtn>
                </div>
            </FormContent>
        </FormDialogModal>
    );
}

export default ListadoDeAutos;
