import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";

export const datosVehiculo = (ingresoId,empresaId) => () => {
    let id = empresaId
    let action = "ver"
    const params = { id, action }
    return api.get(`auto_ventas/ingresos/${ingresoId}`, params).then((response) => {
        return response
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const listarGastosVehiculo = (ingresoId,empresaId) => () => {
    let id = empresaId
    let vehiculo = ingresoId
    const params = { id, vehiculo }
    return api.get('auto_ventas/otro_gasto', params).then((response) => {
        return response
    }).catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
}

export const listarGastos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('auto_ventas/tipo_gasto', params).then(data => {
        const listaGasto = [];
        if (data) {
            data.forEach(item => {
                listaGasto.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return listaGasto;
    }).catch((error) => {
        return [];
    })
}

export const guardarGastos = (data) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const formData = {
        id_vehiculo: id_emp[8],
        empresa: id_emp[5],
        detalleGasto: data
    }
    api.post('/auto_ventas/otro_gasto/', formData).then(() => {
        NotificationManager.success(
            'Gastos guardados',
            'Exito',
            3000
        );
        let ruta = `/auto_ventas/${id_emp[5]}/ingresos`
        dispatch(push(ruta));
    })
    .catch((error) => {
        NotificationManager.error(
            error.msj,
            'Error',
            2000
        );
    })
    
}


export const actions = {
    datosVehiculo,
    listarGastos,
    guardarGastos,
    listarGastosVehiculo
};

export const reducers = {
};

export const initialState = {
    data: [],

};

export default handleActions(reducers, initialState)