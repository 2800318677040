import React, { useEffect, useRef } from "react";
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import ListadoDeAutos from "./Modales/ListadoDeAutos";
import ListadoDeVentas from "./Modales/ListadoDeVentas";
import ListadoDeVendedores from "./Modales/ListadoDeVendedores";
import ListadoCostos from "./Modales/ListadoCostos";

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
    // margin-top: 10px;
`;

function IngresosList(props) {
    const {
        datos,
        reporteListadoDeVehiculos,
        reporteListadoDeVentas,
        reporteListadoDeVendedores,
        listarVendedoresForComboBox,
        reporteCostos,
        parametrosEmpresa,
        listarVehiculos
    } = props;
    const ListadoDeAutosRef = useRef(null);
    const ListadoDeVentasRef = useRef(null);
    const ListadoDeVendedoresRef = useRef(null);
    const ListadoCostoVehiculoRef = useRef(null);
    
    useEffect(() => {
        parametrosEmpresa();
    }, []);

    return (
        <React.Fragment>
            <br />
            <h3
                className="p-2  title rounded shadow"
                style={{
                    background:
                        "linear-gradient(to top right, #790021, #2d2d2d)",
                }}
            >
                Informes
            </h3>
            <br />

            <ButtonContainer>
                <GenBtn onPress={() => ListadoDeAutosRef.current.showModal()}>
                    LISTADO DE AUTOS
                </GenBtn>
                <GenBtn onPress={() => ListadoDeVentasRef.current.showModal()}>
                    LISTADO DE VENTAS
                </GenBtn>
                <GenBtn
                    onPress={() => ListadoDeVendedoresRef.current.showModal()}
                >
                    LISTADO DE VENDEDORES
                </GenBtn>
                {datos.proveedor_autoventa === 'S' &&
                    <GenBtn
                        onPress={() => ListadoCostoVehiculoRef.current.showModal()}
                    >
                        LISTADO DE COSTOS
                    </GenBtn>
                }
            </ButtonContainer>

            <ListadoDeAutos
                dialogRef={ListadoDeAutosRef}
                onSubmit={reporteListadoDeVehiculos}
            />
            <ListadoDeVentas
                dialogRef={ListadoDeVentasRef}
                onSubmit={reporteListadoDeVentas}
                listarVendedoresForComboBox={listarVendedoresForComboBox}
            />
            <ListadoDeVendedores
                dialogRef={ListadoDeVendedoresRef}
                onSubmit={reporteListadoDeVendedores}
            />
            <ListadoCostos
                dialogRef={ListadoCostoVehiculoRef}
                onSubmit={reporteCostos}
                listarVehiculos={listarVehiculos}
            />
        </React.Fragment>
    );
}

export default withRouter(IngresosList);
