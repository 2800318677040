import React, { Component } from 'react';
import Formulario from './FormSeleccionarPeriodo';

class SeleccionarPeriodo extends Component{

    componentDidMount = () => {
        const { periodosList } = this.props;
        periodosList();
    }
    render(){
        const { periodoL, calcularCosto, loader, leerPeriodoD, periodoD } = this.props;
        return(
            <Formulario
                periodoL = {periodoL}
                calcularCosto = {calcularCosto}
                leerPeriodoD = {leerPeriodoD}
                periodoD = {periodoD}
                loader = {loader}
            />
            
        );
    } 
}

export default SeleccionarPeriodo;