import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderField,
    SelectField,
    AsyncSelectField
} from "../../Utils/renderField/renderField";

function ProductoForm(props) {
    const { registroProducto, crear, modulo, listaDep, lectura,
        modificarProducto, listarCuentasContables
    } = props;
    
    const editar = window.location.href.includes('editar');
    let titulo = editar ? 'Modificar producto' : 'Crear producto';
    let disabled = false;
    if (crear === false && editar === false) {
        disabled = true;
        titulo = 'Ver producto';
    }
    let autoFocus = true;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    return (
        <div>
            <NavbarModulo
                modulo={modulo}
                titulo={titulo}
            />
            <br />
            <div className="mb-4 card card-small text-white bg-dark mb-3">
                <div className="p-0 pt-3 d-flex flex-column flex-md-row">
                    <div className="d-flex flex-column flex-1 mx-4">
                        <div className='mb-2 row'>
                            <div className='col-4'>
                                <Field
                                    autoFocus={autoFocus}
                                    name="codigo"
                                    placeholder="Codigo"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Codigo"
                                />
                            </div>
                            <div className='col-8'>
                                <Field
                                    name="producto"
                                    placeholder="Tipo"
                                    className="form-control"
                                    component={renderField}
                                    disabled={disabled}
                                    msj="Producto"
                                />
                            </div>
                        </div>
                        {crear === true &&
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    {listaDep &&
                                        <Field
                                            name="tipo"
                                            options={listaDep}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Departamento"
                                        />
                                    }
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="cuenta"
                                        placeholder="Cuenta contable..."
                                        className="form-control-find"
                                        component={AsyncSelectField}
                                        loadOptions={listarCuentasContables}
                                        msj="Cuenta contable"
                                    />
                                </div> 
                            </div>
                        }
                        {crear === false && editar === false &&
                            <div className='mb-2 row'>
                                <div className='col-6'>
                                    {listaDep &&
                                        <Field
                                            name="tipo"
                                            options={listaDep}
                                            crear={crear}
                                            dato={crear === true ? 0 : lectura.tipo}
                                            component={SelectField}
                                            className="form-select"
                                            disabled={disabled}
                                            msj="Departamento"
                                        />
                                    }
                                </div>
                                <div className='col-6'>
                                    <Field
                                        name="nombre_cuenta"
                                        placeholder="Linea promocional"
                                        className="form-control"
                                        component={renderField}
                                        disabled={disabled}
                                        msj="Cuenta contable"
                                    />
                                </div> 
                            </div>
                        }
                        {crear === false && editar === true &&
                            <div>
                                <div className='mb-2 row'>
                                    <div className='col-6'>
                                        {listaDep &&
                                            <Field
                                                name="tipo"
                                                options={listaDep}
                                                crear={crear}
                                                dato={crear === true ? 0 : lectura.tipo}
                                                component={SelectField}
                                                className="form-select"
                                                disabled={disabled}
                                                msj="Departamento"
                                            />
                                        }
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="nombre_cuenta"
                                            placeholder="Linea promocional"
                                            className="form-control"
                                            component={renderField}
                                            disabled={disabled}
                                            msj="Cuenta contable"
                                        />
                                    </div> 
                                </div>
                                <div className='mb-2 row'>
                                    <div className='col-6'>
                                    </div>
                                    <div className='col-6'>
                                        <Field
                                            name="cuenta_mod"
                                            placeholder="Cuenta contable..."
                                            className="form-control-find"
                                            component={AsyncSelectField}
                                            loadOptions={listarCuentasContables}
                                            msj="Cuenta contable"
                                        />
                                    </div> 
                                </div>
                            </div>
                        }
                        <div className='d-flex flex-row mt-3'>
                            {crear === true && editar === false &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={registroProducto}
                                >
                                    Guardar
                                </button>
                            }
                            {crear === false && editar === true &&
                                <button
                                    className='btn mr-2 mb-3 btn-primary'
                                    type='submit'
                                    onClick={modificarProducto}
                                >
                                    Modificar
                                </button>
                            }
                            <a
                                href={`/#/visitador_medico/${id_emp[5]}/producto`}
                                className='btn btn-secondary mb-3'
                            >
                                Regresar
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ProductoForm.propTypes = {
    crear: PropTypes.bool,
    lectura: PropTypes.object,
    modulo: PropTypes.object,
};

export default reduxForm({
    form: 'FormProductoVM',
    
})(ProductoForm);


