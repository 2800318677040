import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';
const valEmp = __CONFIG__.empresa;

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'ME';
const ME_ADMIN = 'ME_ADMIN';
const EMPRESA = 'EMPRESA';
const EMPRESA_DATA = 'EMPRESA_DATA';
const TOTAL_EMPRESAS = 'TOTAL_EMPRESAS';
const MODULO_DATA = 'MODULO_DATA';
const MODULO = 'MODULO';
const TOTALES = 'TOTALES';


export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

export const setEmpresa = empresa => ({
    type: EMPRESA,
    empresa,
});

export const setEmpresaData = empresaData => ({
    type: EMPRESA_DATA,
    empresaData,
});

export const setModulo = modulo => ({
    type: MODULO,
    modulo,
});

export const setModuloData = moduloData => ({
    type: MODULO_DATA,
    moduloData,
});

export const setTotales = total => ({
    type: TOTALES,
    total,
});

export const setTotalEmp = totalE => ({
    type: TOTAL_EMPRESAS,
    totalE,
});


// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/token', data).then((response) => {
        
        if ((parseInt(response.totales.totEmpresas))>=2){
            localStorage.setItem('token', response.token);
            if (parseInt(response.totales.totSucursal) === 0 && parseInt(response.totales.totUsuarios) === 0 ){
                let empresas = []
                response.empresa.forEach(emp=>{
                    empresas.push({
                        razon_social: emp.empresa.razon_social,
                        id: emp.empresa.id,
                        empresa_sucursal: emp.empresa.empresa_sucursal,
                        centro_costo: emp.empresa.centro_costo,
                        validar_nit: emp.empresa.validar_nit,
                        validar_dpi: emp.empresa.validar_dpi,
                        contabilidad: emp.empresa.contabilidad,
                        inmobiliaria: emp.empresa.inmobiliaria,
                        saldos: emp.empresa.saldos,
                        direccion: emp.empresa.direccion,
                        identificador: emp.empresa.identificador,
                        moneda: emp.empresa.moneda,
                        pais: emp.empresa.pais,
                        telefono: emp.empresa.telefono,
                        tipo_cambio: emp.empresa.tipo_cambio
                    })
                })
                dispatch(setEmpresa(empresas));
            }else{
                dispatch(setEmpresa(response.empresa));
            }
            dispatch(setModulo(response.modulo));
            dispatch(setTotales(response.totales));
            dispatch(push("/empresas"));
        }else{
            if (parseInt(response.totales.totSucursal) === 0 && parseInt(response.totales.totUsuarios) === 0 ){
                let empresas = []
                response.empresa.forEach(emp=>{
                    empresas.push({
                        razon_social: emp.empresa.razon_social,
                        id: emp.empresa.id,
                        empresa_sucursal: emp.empresa.empresa_sucursal,
                        centro_costo: emp.empresa.centro_costo,
                        validar_nit: emp.empresa.validar_nit,
                        validar_dpi: emp.empresa.validar_dpi,
                        contabilidad: emp.empresa.contabilidad,
                        inmobiliaria: emp.empresa.inmobiliaria,
                        saldos: emp.empresa.saldos,
                        direccion: emp.empresa.direccion,
                        identificador: emp.empresa.identificador,
                        moneda: emp.empresa.moneda,
                        pais: emp.empresa.pais,
                        telefono: emp.empresa.telefono,
                        tipo_cambio: emp.empresa.tipo_cambio
                    })
                })
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
            if (parseInt(response.totales.totSucursal) === 0 && parseInt(response.totales.totUsuarios) === 1 ){
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
            if (parseInt(response.totales.totSucursal) === 1 && parseInt(response.totales.totUsuarios) === 1 ){
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
            if (parseInt(response.totales.totSucursal) > 1 && parseInt(response.totales.totUsuarios) === 1 ){
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
            if (parseInt(response.totales.totSucursal) > 1 && parseInt(response.totales.totUsuarios) > 2 ){
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
            if (parseInt(response.totales.totSucursal) === 1 && parseInt(response.totales.totUsuarios) > 1 ){
                localStorage.setItem('token', response.token);
                dispatch(push("/modulos"));
                dispatch(setModulo(response.modulo));
                dispatch(setTotales(response.totales));
            }
        }
    }).catch((error) => {
        NotificationManager.error(
            error.msj, 
            'ERROR', 
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getTotal = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let params;
    let id = ''
    if (valEmp === 'fage'){
        id = id_emp[6];
        params = { id };
    }else{
        id = id_emp[5];
        params = { id };
    }
    api.get('/user/totales/',params).then((response)=>{
        dispatch(setTotales(response.totales));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar totales',
            'Error',
            6000
        );
    })
}

export const getModulos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    
    let modulos = []
    let params = {id}
    dispatch(setLoader(true));
    api.get('/modulos/modulo_empresa/', params).then((response)=>{
        if (response.admin === 1){
            response.modulos.forEach(item=>{
                modulos.push({
                    modulo: item.modulo,
                    ruta: item.ruta,
                    logo: item.logo
                })
            })
        }else{
            response.modulosUser.forEach(item=>{
                modulos.push({
                    modulo: item.modulo,
                    ruta: item.ruta,
                    logo: item.logo
                })
            })
        }
        
        dispatch(setModulo(modulos));
        dispatch(setEmpresaData(response.empresa));
        dispatch(setTotalEmp(response.totEmpresas));
        
        if (response.empresa.tipo_cambio === 'S'){
            api.get('/tipo_cambio/', params).then((response)=>{
                if (response.tipoCambio === 'S' && response.tipoCambioDia ){
                    Swal.fire({
                        title: 'Tipo de cambio Banco de Guatemala',
                        text: moment().format('L')+' --- '+response.tipoCambioDia,
                        type: 'info',
                        showCancelButton: true,
                        confirmButtonText: '¡Sí, guardar!',
                        cancelButtonText: 'No, cancelar',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            const formData = {
                                tipoCambio: response.tipoCambioDia,
                                empresa: id_emp[5]
                            }
                            api.post('/tipo_cambio/', formData).then((response)=>{
                                NotificationManager.success(
                                    'Tipo de cambio guardado',
                                    'Exito',
                                    3000
                                );
                                //dispatch(push("/modulos"));
                            }).catch((error)=>{
                                NotificationManager.error(
                                    'Ocurrió un error al guardar tipo de cambio',
                                    'Error',
                                    6000
                                );
                            })
                        }
                    });
                }
            }).catch((error)=>{
                NotificationManager.error(
                    'Ocurrió un error al consultar tipo de cambio',
                    'Error',
                    6000
                );
            })
        }
        if (response.empresa.tipo_cambio === 'N'){
            //dispatch(push("/modulos"));
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar modulos',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const getEmpresas = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('/empresa/empresa_usuario/').then((response)=>{
        if (parseInt(response.admin) === 0){
            let empresas = []
                response.empresa.forEach(emp=>{
                    empresas.push({
                        razon_social: emp.empresa.razon_social,
                        id: emp.empresa.id,
                        empresa_sucursal: emp.empresa.empresa_sucursal,
                        centro_costo: emp.empresa.centro_costo,
                        direccion: emp.empresa.direccion,
                        validar_nit: emp.empresa.validar_nit,
                        validar_dpi: emp.empresa.validar_dpi,
                        contabilidad: emp.empresa.contabilidad,
                        inmobiliaria: emp.empresa.inmobiliaria,
                        saldos: emp.empresa.saldos,
                        identificador: emp.empresa.identificador,
                        logo: emp.empresa.logo,
                        pais: emp.empresa.pais,
                        telefono: emp.empresa.telefono,
                        tipo_cambio: emp.empresa.tipo_cambio
                    })
                })
            dispatch(setEmpresa(empresas));
            dispatch(push("/empresas"));
        }else{
            dispatch(setEmpresa(response.results));
            dispatch(push("/empresas"));
        }
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar las empresas',
            'Error',
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(response => {
        dispatch({type: ME, me: response });
        dispatch(initializeForm('FormProfile', response));
    }).catch(() => {
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout/').then((response) => {
        if (response.status === 204){
            localStorage.removeItem('token');
            localStorage.clear();
            dispatch(push("/login"));
        }
    }).catch(() => {
    }).finally(() => {});
};

export const getModulo = (id, modulo) => (dispatch) => {
    let params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch(setModuloData(response.modulos));
        dispatch(setEmpresaData(response.empresa));
    }).catch((error) => {
        if (error.status === 401){
            NotificationManager.error(
              error.body.msj,
              'Error',
              0
            );
        }
        if (error.status === 404){
            NotificationManager.error(
              'Ocurrió un error en buscar modulo',
              'Error',
              0
            );
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const loginTools = () => (dispatch, getStore) => {
    let nivel = 0;
    const data = getStore().form.loginTools.values;
    if (data.username === 'tax@aconsi.com'){
        nivel = 1
    }
    const formData = {
        username: data.username,
        password: data.password,
        nivel: nivel
    }
    dispatch(setLoader(true));
    api.post('user/token', formData).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(push('/tools/home'));
    }).catch((error) => {
        NotificationManager.error(
            error.msj, 
            'ERROR', 
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const loginFage = () => (dispatch, getStore) => {
    let nivel = 2;
    const data = getStore().form.loginFage.values;
    const formData = {
        username: data.username,
        password: data.password,
        nivel: nivel
    }
    dispatch(setLoader(true));
    api.post('user/token', formData).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(push('/empresas'));
    }).catch((error) => {
        NotificationManager.error(
            error.msj, 
            'ERROR', 
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const loginRegistro = () => (dispatch, getStore) => {
    let nivel = 0;
    const data = getStore().form.loginRegistro.values;
    if (data.username === 'tax@aconsi.com'){
        nivel = 1
    }
    const formData = {
        username: data.username,
        password: data.password,
        nivel: nivel
    }
    dispatch(setLoader(true));
    api.post('user/token', formData).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(push('/pro/registro'));
    }).catch((error) => {
        NotificationManager.error(
            error.msj, 
            'ERROR', 
            6000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMeVM = () => (dispatch) => {
    api.get('/user/meAdmin').then(response => {
        dispatch({type: ME, me: response.user });
        dispatch({type: ME_ADMIN, meAdmin: response.admin });
        dispatch(initializeForm('FormProfile', response));
    }).catch(() => {
    }).finally(() => {});
};

export const actions = {
    onSubmit,
    logOut,
    getTotal,
    getModulos,
    getEmpresas,
    getMe,
    getModulo,
    loginTools,
    loginFage,
    loginRegistro,
    getMeVM
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [ME_ADMIN]: (state, { meAdmin }) => {
        return {
            ...state,
            meAdmin,
        };
    },
    [EMPRESA]: (state, { empresa }) => {
        return {
            ...state,
            empresa,
        };
    },
    [EMPRESA_DATA]: (state, { empresaData }) => {
        return {
            ...state,
            empresaData,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [MODULO_DATA]: (state, { moduloData }) => {
        return {
            ...state,
            moduloData,
        };
    },
    [TOTALES]: (state, { total }) => {
        return {
            ...state,
            total,
        };
    },
    [TOTAL_EMPRESAS]: (state, { totalE }) => {
        return {
            ...state,
            totalE,
        };
    },
};

export const initialState = {
    loader: false,
    me: [],
    meAdmin: [],
    empresaData: [],
    moduloData: [],
    total: [],
    totalE: []
};

export default handleActions(reducers, initialState);
