import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/proveedor';
import Proveedor from './ProveedorForm';

const ms2p = (state) => {
  return {
    ...state.vm_proveedor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Proveedor);
