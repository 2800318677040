import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DataTable from "../../components/DataTableW";
import * as Fa from "react-icons/fa";
import * as yup from 'yup';
import GenBtn from "../../components/GenBtn";
import { withRouter } from "react-router";
import {
  FicComboBox,
  FicInput,
  FicTextArea,
  FicNumber,
  FicDatePicker
} from "../../Utils/FormInputComponents/index";
const schema = yup.object().shape({
  gasto: yup.mixed().required('Seleccione un tipo de gasto'),
  monto: yup
    .number()
    .typeError('Debe ser un número válido')
    .positive('El monto debe ser positivo')
    .required('Campo requerido'),
  fecha: yup.date().required('Fecha requerida').typeError('Fecha inválida'),
  descripcion: yup.string().max(200, 'Máximo 200 caracteres'),
});

function OtroGasto(props) {
  const {
    history,
    listarGastos,
    guardarGastos
  } = props;
  const urlParams = props.match.params;
  const empresa = urlParams.empresa;
  const [codigoV, setCodigoV] = useState("");
  const [marcaV, setMarcaV] = useState("");
  const [lineaV, setLineaV] = useState("");
  const [placaV, setPlacaV] = useState("");
  const [gastosRegistrados, setGastosRegistrados] = useState([]);
  
  const { 
    control, 
    handleSubmit, 
    reset,
    formState: { } 
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      codigo: "",
      marca: "",
      linea: "",
      placa: "",
      gasto: null,
      fecha: "",
      monto: "",
      descripcion: "",
    }
  });

  // Cargar datos iniciales
  useEffect(() => {
    const { datosVehiculo, listarGastos, listarGastosVehiculo } = props;
    let ruta = window.location.href;
    let id_auto = ruta.split('/');
    let datosV = datosVehiculo(id_auto[8],id_auto[5]);
    let gastos = listarGastosVehiculo(id_auto[8],id_auto[5]);
    let listaGasto = [];
    gastos.then(g => {
      g.forEach(item => {
        let gastoI = {}
        gastoI["id"] = item.id
        gastoI["tipo"] = item.gastoN
        gastoI["monto"] = parseFloat(item.monto)
        gastoI["fecha"] = item.fecha_gasto
        gastoI["descripcion"] = item.descripcion
        gastoI["idTipoGasto"] = item.idGasto
        listaGasto.push(gastoI);
      })
      setGastosRegistrados([...gastosRegistrados, ...listaGasto]);
    })
    datosV.then(d => { 
      setCodigoV(d.codigo)
      setMarcaV(d.marca)
      setLineaV(d.linea)
      setPlacaV(d.placa)
    });
    listarGastos();
  }, []);

  const agregarGasto = (data) => {
    const nuevoGasto = {
      id: Date.now(),
      tipo: data.gasto.label,
      monto: data.monto,
      fecha: data.fecha,
      descripcion: data.descripcion,
      idTipoGasto: data.gasto.value
    };
    
    setGastosRegistrados([...gastosRegistrados, nuevoGasto]);
    reset();
  };

  const eliminarGasto = (id) => {
    setGastosRegistrados(gastosRegistrados.filter(g => g.id !== id));
  };

  const columns = [
    {
      name: "Fecha",
      selector: (row) => {
        const fechaFormateada = new Date(row.fecha).toLocaleDateString('es-GT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
        return fechaFormateada;
    },
      sortable: true,
    },
    {
      name: "Gasto",
      selector: (row) => row.tipo,
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) => {
        const monto = parseFloat(row.monto); 
        if (isNaN(monto)) {
          return "0.00";
        }
        return monto.toLocaleString('es-GT', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
      },
      right: true,
      sortable: true,
    },
    {
      cell: (row) => (
        <GenBtn
          textColor="hsla(0, 100%, 50%, 1)"
          background="hsla(0, 100%, 50%, 0.2)"
          title="Eliminar"
          onPress={() => eliminarGasto(row.id)}
        >
          <Fa.FaTrash />
        </GenBtn>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  
  return (
    <React.Fragment>
      <br />
      <h3
        className="p-2  title rounded shadow"
        style={{
          background:
              "linear-gradient(to top right, #790021, #2d2d2d)",
        }}
      >
        Otros gastos - Vehículos
      </h3>
      <br />
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Datos del Vehículo
      </h4>
      <div className="inputs-container">
        <FicInput
          label="Codigo"
          name="codigo"
          placeholder={`${codigoV}`}
          control={control}
          isReadOnly={true}
        />
        <FicInput
          label="Marca"
          name="marca"
          placeholder={`${marcaV}`}
          control={control}
          isReadOnly={true}
        />
        <FicInput
          label="Linea"
          name="linea"
          placeholder={`${lineaV}`}
          control={control}
          isReadOnly={true}
        />
        <FicInput
          label="Placa"
          name="placa"
          placeholder={`${placaV}`}
          control={control}
          isReadOnly={true}
        />
      </div>
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Gastos
      </h4>
      <form onSubmit={handleSubmit(agregarGasto)}>
        <div className="inputs-container">
          <FicComboBox
            label="Gasto"
            name="gasto"
            control={control}
            loadOptions={listarGastos}
          />
          <FicDatePicker
            label="Fecha"
            name="fecha"
            control={control}
          />
          <FicNumber
            formatOptions={{
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }}
            label="Monto"
            name="monto"
            control={control}
          />    
        </div>
        <div className="observaciones-container">
          <FicTextArea
            label="Descripción"
            name="descripcion"
            control={control}
          />
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center mb-2" 
          style={{ height: "80px" }}
        >
        <GenBtn
          type="submit"
          BtnColor="#1976D2"
          style={{ 
            width: "200px", 
            height: "40px", 
            backgroundColor: "#1976D2", 
            color: "#FFFFFF", 
            border: "none", 
            borderRadius: "4px", 
            cursor: "pointer", 
            transition: "background-color 0.3s ease", 
            display: "flex", 
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Agregar gasto
      </GenBtn>
      </div>
      </form>
      <h4
        className="p-2  title rounded shadow"
        style={{
          background:
            "linear-gradient(to top right, #a83232, #6d1c1c)",
        }}
      >
        Detalle de gastos
      </h4>
      <DataTable
        columns={columns}
        data={gastosRegistrados}
      />
      <br/>
      <div className="d-flex flex-row justify-content-start mb-2">
        <GenBtn
          onPress={() => {
            guardarGastos(gastosRegistrados);
          }}
          autofocus="autofocus"
        >
          Guardar gastos
        </GenBtn>
        <GenBtn
          BtnColor="#787878"
          style={{ width: "100px", height: "40px", marginLeft: "16px"}}
          onPress={(e) => {
            history.push(`/auto_ventas/${empresa}/ingresos`);
          }}
        >
          Regresar
        </GenBtn>
      </div>
      <br/>
    </React.Fragment>
  );
}

export default  withRouter(OtroGasto);
