import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/visitador_medico/otros_gastos_inversion';
import OtrosGastos from './OtrosGastosInversion';


const ms2p = (state) => {
  return {
    ...state.vm_otros_gastos_inversion,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(OtrosGastos);

