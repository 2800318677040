import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

import {
    SelectField,
    AsyncSelectField
  } from "../../Utils/renderField/renderField";

class CuentaContableList extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount = () => {
        const { listarCuentasConfig, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listarCuentasConfig();
        getModulo(modulo[5],modulo[4])
    }
    
    render(){
        const { 
            loader, 
            registroConfigCuenta,
            datos,
            modulo,
            eliminarCuenta,
            listarCuentasContables
        } = this.props;
        
        const tipo = [
            { label: "IVA CREDITO", value: "IVA CREDITO" },
            { label: "PROVEEDORES", value: "PROVEEDORES" },
        ];
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let titulo = 'Cuentas contables';
        return(
            <React.Fragment>
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Cuentas contables</h3>
                <div className="row mb-2">
                    <div className='col-4'>
                        <div className="form-floating">
                            <Field
                                name="tipo"
                                options={tipo}
                                dato={0}
                                component={SelectField}
                                className="form-select"
                                msj="Seleccionar tipo"
                            />
                        </div>
                    </div>
                    <div className='col-6'>
                        <Field
                            name="cuenta"
                            placeholder="Cuenta contable..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarCuentasContables}
                            msj="Cuenta"
                        />
                    </div>             
                </div>    
                <button
                    className='btn mr-2 mb-3 btn-primary'
                    onClick={registroConfigCuenta}
                >
                    Guardar
                </button>
                <a
                    href = {`/#/visitador_medico/${id_emp[5]}`}
                    className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
                {datos &&
                    <Grid 
                        hover 
                        striped 
                        data={datos} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="descripcion_cuenta"
                            width="350"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Cuenta
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre_cuenta"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Descripción
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            dataAlign="center"
                            width="105"
                            dataSort
                            dataFormat={standardActions({ 
                                eliminar: eliminarCuenta })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: 'FormParmetroCuentasContablesVM',
})(CuentaContableList );
