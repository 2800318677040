import React, { Component } from 'react';
import Grid from '../../Utils/GridFiltro';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { standardActions } from '../../Utils/GridFiltro/StandardActions';
import NavbarModulo from '../../components/NavbarModulo';

import {
    renderCurrencyFloat,
    AsyncSelectField
  } from "../../Utils/renderField/renderField";

class Proveedor extends Component{
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount = () => {
        const { listarProveedoresImp, getModulo } = this.props;
        let ruta = window.location.href;
        let modulo = ruta.split('/');
        listarProveedoresImp();
        getModulo(modulo[5],modulo[4])
    }
    
    render(){
        const { 
            loader, 
            registroProveedor,
            datos,
            modulo,
            eliminarProveedor,
            listarProveedores
        } = this.props;
        
        let ruta = window.location.href;
        let id_emp = ruta.split('/');
        let titulo = 'Proveedores';
        return(
            <React.Fragment>
                <NavbarModulo
                    modulo={modulo}
                    titulo={titulo}
                />
                <br />
                <h3 className="p-2 bg-secondary title">Proveedores</h3>
                <div className="row mb-2">
                    <div className='col-10'>
                        <Field
                            name="proveedor"
                            placeholder="Proveedor..."
                            className="form-control-find"
                            component={AsyncSelectField}
                            loadOptions={listarProveedores}
                            msj="Proveedor"
                        />
                    </div>
                </div>    
                <button
                    className='btn mr-2 mb-3 btn-primary'
                    onClick={registroProveedor}
                >
                    Guardar
                </button>
                <a
                    href = {`/#/visitador_medico/${id_emp[5]}`}
                    className='btn btn-secondary mb-3'
                >
                Regresar
                </a>
                {datos &&
                    <Grid 
                        hover 
                        striped 
                        data={datos} 
                        loading={loader} 
                        headerStyle={ { background: 'black' } } 
                        //onPageChange={onPageChange}
                        //onSortChange={onSortChange} 
                    >
                        <TableHeaderColumn
                            isKey
                            dataField="identificador"
                            width="150"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Identificador
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            thStyle={ 
                                {color: 'white'}}
                            tdStyle={
                                {color: 'white'}}
                        >
                            Nombre
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            thStyle={
                            {color: 'white'}}
                            dataField="id"
                            dataAlign="center"
                            width="105"
                            dataSort
                            dataFormat={standardActions({ 
                                eliminar: eliminarProveedor })} 
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid>
                }
            </React.Fragment>
        );
    }
}
export default reduxForm({
    form: 'FormProveedorVM',
})(Proveedor);
